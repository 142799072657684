<template lang="pug">
.app(:style='htmlStyle')
  img.logo(alt="Conecta" src="./assets/logo.png")
  .contact
    o-tooltip(label="escríbenos aquí :)" variant="primary" position="bottom")
      a(href='mailto:ximena@venyconecta.cl')
        i.icon.fas.fa-envelope
  #preload
    img(src='./assets/bg-1.jpg')
    img(src='./assets/bg-2.jpg')
    img(src='./assets/bg-3.jpg')
    img(src='./assets/bg-4.jpg')
    img(src='./assets/bg-5.jpg')
    img(src='./assets/bg-6.jpg')
    img(src='./assets/bg-7.jpg')
    img(src='./assets/bg-8.jpg')
    img(src='./assets/bg-9.jpg')
    img(src='./assets/bg-10.jpg')
    img(src='./assets/bg-11.jpg')
  //- https://codepen.io/dudleystorey/pen/qEoKzZ
</template>

<script>

export default {
  name: 'App',
  data() {
    return { 
      bgImageArray: [],
      htmlStyle: {},
      secs: 6000,
      imgCount: 0
    }
  },
  methods: {
    backgroundSequence(tempArr, imgsNum) {
      this.imgCount++
      if (this.imgCount >= imgsNum) {
        this.imgCount = 0
      }
      setTimeout( () => this.backgroundSequence(tempArr, imgsNum), this.secs)
      this.htmlStyle = {
        backgroundImage: `url(${require('./assets/'+ tempArr[this.imgCount])})`
      }
    }
  },
  created() {
    const tempArr = []
    for (let i = 1; i < 11; i++) {
      tempArr.push(`bg-${i}.jpg`)
      
    }
    tempArr.sort(() => (Math.random() > .5) ? 1 : -1)
    this.backgroundSequence(tempArr, tempArr.length)
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Raleway&display=swap');

:root {
  --oruga-variant-primary: #929292;
  --oruga-tooltip-content-font-size: 0.7em;
}
#preload{
  display: none;
}
html, body { height: 100%; margin:0; background-color: black}

*{
  font-family: 'Montserrat', sans-serif;
}

.app {
  background-image: url("./assets/bg-1.jpg"), url("./assets/bg-2.jpg"), url("./assets/bg-3.jpg"), url("./assets/bg-4.jpg"), url("./assets/bg-5.jpg"), url("./assets/bg-6.jpg"), url("./assets/bg-7.jpg"), url("./assets/bg-8.jpg"), url("./assets/bg-9.jpg"), url("./assets/bg-10.jpg"), url("./assets/bg-11.jpg");
  height: 100vh;
  margin: 0;
  background-size: cover;
  background-position: center;
  transition-property: transform;
  transition: 2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contact{
  position: absolute;
  bottom: 90px;
  a{
    color: white;
    text-decoration: none;
  }
  
}
.icon{
  font-size: 2em;
}
img.logo{
  width: 100%;
  max-width: 480px;
}
@media screen and (max-width: 600px) {
  img.logo{
    width: 100%;
    max-width: 320px;
  }
}

</style>
