import { createApp } from 'vue'
import App from './App.vue'
import Oruga from '@oruga-ui/oruga-next'
import '@oruga-ui/oruga-next/dist/oruga-full-vars.css'

import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { fas } from '@fortawesome/free-solid-svg-icons'


import {
  faEnvelope,
  faCaretUp,
  faUser
} from '@fortawesome/free-solid-svg-icons'

library.add(
  fas
)
dom.watch()

createApp(App)
.component("font-awesome-icon", FontAwesomeIcon)
.use(Oruga, {
  iconComponent: 'vue-fontawesome',
  iconPack: 'fas'
})


.mount('#app')
